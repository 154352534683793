import dynamic from "next/dynamic";
import parse from "html-react-parser";
import Link from "next/link";
import { SiteContext } from "../../Contexts/SiteContext";
import { SelectImageForDevice } from "../../../utils";
import CountdownTimer from "../Quest/CountdownTimer";
import { addDataLayerEvent } from "../../../utils/data.layer.utils/data.layer.util";
import { pickDataValuesForButtonsGTM } from "../../../utils/data.layer.utils/buttons.data.layer.util";

const LazyLoad = dynamic(() => import('../Image/LazyLoad'));
const CarouselSlider = dynamic(() => import("../Slider/CarouselSlider"));
// This is the default banner

const CarouselBanner = ({ category = null, pageData, banners: { banners, settings } }) => {
	const bannerCopy = (title, description) => {
		return <div className="copy">
			<div className="line" />

			{title !== null &&
				<h1>{title}</h1>
			}
			{description !== null &&
				<h6>{parse(description)}</h6>
			}
		</div>
	}

	const timer = (timerData) => {
		return <div className={"countdownTimerHolder alignCenter"}>
			<CountdownTimer data={timerData} />
		</div>
	}

	const linkBanner = (banner, i, deviceType, baseTracking) => {
		return (
			<div className="banner" key={i}>
				{banner.bannerLink.type === 'Entry' &&
					<Link href={`/${banner.bannerLink.uri}`} >
						<a title={banner.bannerLink.text}>
							<div className="imageHolder">
								{banner.countdownTimer && banner.countdownTimer !== null && timer(banner.countdownTimer)}
								<div className="overlay">
									{!!banner.media.desktop.overseasModelShown && (
										<h6 className="disc">*Overseas model shown</h6>
									)}
								</div>
								{!!banner.media && !!banner.media.desktop &&
									<LazyLoad
										image={SelectImageForDevice(deviceType, banner)}
										alt={banner.title}
										title={banner.title}
									/>
								}
							</div>
							<div className="productBannerCopyHolder">
								<div className={`productBannerCopy ${banner.title === null && "alignBottom"} ${settings.bannerType === 'miniBanner' && "alignCenter"}`}>
									{banner.title != null && settings.bannerType === 'heroBanner' && bannerCopy(banner.title, banner.description)}
								</div>
							</div>
						</a>
					</Link>
				}

				{banner.bannerLink.type === 'Url' &&
					<a title={banner.bannerLink.text} href={banner.bannerLink.url} target={`${banner.bannerLink.target}`}>
						<div className="imageHolder">
							<div className="overlay">
								{!!banner.media.desktop.overseasModelShown && (
									<h6 className="disc">*Overseas model shown</h6>
								)}
							</div>
							{!!banner.media && !!banner.media.desktop &&
								<LazyLoad
									image={SelectImageForDevice(deviceType, banner)}
									alt={banner.title}
									title={banner.title}
								/>
							}
						</div>
						<div className="productBannerCopyHolder">
							<div className={`productBannerCopy ${banner.title === null && "alignBottom"} ${settings.bannerType === 'miniBanner' && "alignCenter"}`}>
								{banner.title != null && settings.bannerType === 'heroBanner' && bannerCopy(banner.title, banner.description)}
							</div>
						</div>
					</a>
				}
			</div>
		);
	}

	const buttonBanner = (banner, i, deviceType, baseTracking) => {
		return <div className="banner" key={i}  >
			<div className="imageHolder">

				{banner.countdownTimer !== null && timer(banner.countdownTimer)}

				<div className="overlay">
					{banner.media.desktop.overseasModelShown ? (
						<h6 className="disc">*Overseas model shown</h6>
					) : null}
				</div>

				{banner.media !== null && banner.media.desktop !== null &&
					<LazyLoad
						image={SelectImageForDevice(deviceType, banner)}
						alt={banner.title}
						title={banner.title}
					/>
				}
			</div>
			<div className="productBannerCopyHolder">
				<div className={`productBannerCopy ${banner.title === null && "alignBottom"} ${settings.bannerType === 'miniBanner' && "alignCenter"}`}>
					{banner.title != null && settings.bannerType === 'heroBanner' && bannerCopy(banner.title, banner.description)}
					{banner.buttons && banner.buttons !== null && banner.buttons.length > 0 &&
						<div className="buttons">
							{banner.buttons.map((button, i) => {
								{
									if (button.type === "button") {
										return (
											<a
												title={button.buttonLink.text}
												href={button.buttonLink.url}
												className={`waves-effect waves-light btn-large bb${button.colour}`
												}
												key={i}
												target={button.buttonLink.target}
												onClick={(e) => {
													const picked = pickDataValuesForButtonsGTM(category, null, button);

													addDataLayerEvent('BannerEventLinkButton', {
														...baseTracking,
														...picked,
													});
												}}
											>
												{button.buttonLink.text}
											</a>
										);
									} else if (button.type === "brochure") {
										return (
											<a
												title={button.brochureAsset.text}
												href={button.brochureAsset.url}
												className={`waves-effect waves-light btn-large bb${button.colour}`
												}
												key={i}
												download={button.brochureAsset.url}
												// onClick={(e) => {
												// 	createDataLayer(e, button)
												// }}
												target="_blank"
											>
												{button.brochureAsset.text}
											</a>
										);
									} else if (button.type === "form" && hasProperty(button.form, "name") && button.form.name != null) {
										return (
											<a
												title={button.form.name}
												className={`waves-effect waves-light btn-large bb${button.colour}`
												}
												key={index}
											// onClick={(e) => {
											// 	createDataLayer(e, button)
											// 	getFormData(button.form.handle);
											// 	setTimeout(() => {
											// 		setOpenBATD(true)
											// 	}, 750)
											// }}
											>
												{button.title}
											</a>
										);
									}
								}


							})}
						</div>
					}
				</div>

			</div>

		</div >
	}

	return (
		<SiteContext.Consumer>
			{({ deviceType }) => (
				<div className="bannerCarousel category">
					<div className="carouselSlider" id="standardCarouselSlider">
						<CarouselSlider content={banners} bullets={settings} loop={true} settings={settings}>
							{banners.map((banner, i) => {
								const baseTracking = {
									url: pageData.url,
									title: pageData.title,
									category
								};

								if (banner.bannerLink) {
									return linkBanner(banner, i, deviceType, baseTracking);
								} else {
									return buttonBanner(banner, i, deviceType, baseTracking);
								}
							})}
						</CarouselSlider>
					</div>
				</div>
			)}
		</SiteContext.Consumer>
	);
};

export default CarouselBanner;