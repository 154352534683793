import { useState, useEffect } from 'react';


const CountdownTimer = ({ data }) => {

    // const [timeString, setTimeString] = useState(null);

    const [timeObject, setTimeObject] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [distanceTo, setDistanceTo] = useState(0);


    let interval
    // Set the date we're counting down to
    const countDownDate = new Date(data.date).getTime();

    useEffect(() => {
        // only start timer if date hasnt expired
        if (countDownDate - new Date().getTime() > 0) {
            interval = setInterval(setNewTime, 1000)
        }
        return (() => { clearInterval(interval) })

    }, [])



    // Update the count down every 1 second

    const setNewTime = () => {

        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;
        setDistanceTo(distance)

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is over 
        if (distance < 0) {
            clearInterval(interval)
            setTimeObject({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
        else {
            // console.log(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
            setTimeObject({ days: days, hours: hours, minutes: minutes, seconds: seconds });
        }


    };


    return (
        // <>
        //     {distanceTo > 0 ?
        <div className="countdownTimer">
            <div className="unitWrapper">
                <div className='unit'>{timeObject.days}</div>
                {/* <div className="line" /> */}
                <div className='description'>DAYS</div>
            </div>
            <div className="unitWrapper">
                <div className='unit'>{timeObject.hours}</div>
                {/* <div className="line" /> */}
                <div className='description'>HOURS</div>
            </div>
            <div className="unitWrapper">
                <div className='unit'>{timeObject.minutes}</div>
                {/* <div className="line" /> */}
                <div className='description'>MINS</div>
            </div>
            <div className="unitWrapper">
                <div className='unit'>{timeObject.seconds}</div>
                {/* <div className="line" /> */}
                <div className='description'>SECS</div>
            </div>
        </div>
        //         : <div className="countdownTimer">
        //             <div className="expired">
        //                 COUNTDOWN EXPIRED
        //             </div>
        //         </div>}
        // </>

    )

}




export default CountdownTimer